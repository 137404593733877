import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import React, { useState, useEffect, useRef, memo } from 'react'
import {
  BiPlusCircle,
  BiCopyAlt,
  BiArrowFromLeft,
  BiArrowFromRight,
} from 'react-icons/bi'
import TradingViewWidget from '../components/Symbolview'
import axios from 'axios'

import Swal from 'sweetalert2'
import { Link } from 'react-router-dom'
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa'

export default function Trading({
  user,
  getUser,
  getTraders,
  traders,
  logout,
}) {
  const [navState, setNavState] = useState('hide')
  const [trades, setTrades] = useState([])
  const [option, setOption] = useState('NASDAQ')
  const [activeTrades, setActiveTrades] = useState({ count: 0, msg: '' })
  const [page, setPage] = useState(1)
  const tradeform = useRef()
  const assets = {
    NASDAQ: [
      'AAPL',
      'NVDA',
      'BBBY',
      'QQQ',
      'META',
      'MSFT',
      'GOOGL',
      'TQQQ',
      'AMD',
      'COIN',
      'SMTC',
      'NFLX',
    ],
    FX: [
      'EURUSD',
      'GBPUSD',
      'CHFUSD',
      'AUDUSD',
      'JPYUSD',
      'NZDUSD',
      'CADUSD',
      'CADJPY',
      'CHYUSD',
      'CHYNZD',
      'EURJPY',
      'GBPJPY',
      'USDCAD',
      'NZDCAD',
      'EURGBP',
    ],
    BINANCE: [
      'BTCUSDT',
      'ETHUSDT',
      'XRPUSDT',
      'SOLUSDT',
      'ADAUSDT',
      'SHIBUSDT',
      'BNBUSDT',
      'DOTUSDT',
      'LTCUSDT',
      'BCHUSDT',
      'BTCETH',
      'LTCDOT',
    ],
  }
  const [asset, setAsset] = useState(assets[option][0])
  function getTrades() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=trades'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setTrades(response?.data?.reverse())
      })
      .catch((error) => console.error(error))
  }
  const Copy = (a) => {
    navigator.clipboard.writeText(a)
    Swal.fire('CONNECTION KEY COPIED', a, 'success')
  }
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  const trade = (type) => {
    let data = new FormData(tradeform.current)
    data.append('TYPE', type)
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=trade'}`,
      withCredentials: false,
      data: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        if (response?.data?.activeTrades) {
          setCount(response?.data.count)
          setActiveTrades({
            count: response?.data.count,
            msg: response.data.msg,
          })
        } else {
          Swal.fire('TRADE REPORT', response.data.msg, response.data.type)
        }
      })
      .catch((error) => console.error(error))
  }
  const technicalcontainer = useRef()
  const tickercontainer = useRef()
  const [count, setCount] = useState(activeTrades?.count)
  useEffect(() => {
    //Implementing the setInterval method
    if (count && count >= 0) {
      const interval = setInterval(() => {
        setCount(parseInt(count) - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
    //Clearing the interval

    if (count == 0 && activeTrades.msg !== '') {
      getUser()
      getTrades()
      Swal.fire('TRADE REPORT', activeTrades.msg, 'success')
      setActiveTrades({
        count: 0,
        msg: '',
      })
    }
  }, [activeTrades?.count, count])
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
    script.type = 'text/javascript'
    script.setAttribute('id', 'tickercontainer')
    script.async = true
    script.innerHTML = `
        {
           "symbols": [
    {
      "proName": "FOREXCOM:SPXUSD",
      "title": "S&P 500 Index"
    },
    {
      "proName": "FOREXCOM:NSXUSD",
      "title": "US 100 Cash CFD"
    },
    {
      "proName": "FX_IDC:EURUSD",
      "title": "EUR to USD"
    },
    {
      "proName": "BITSTAMP:BTCUSD",
      "title": "Bitcoin"
    },
    {
      "proName": "BITSTAMP:ETHUSD",
      "title": "Ethereum"
    }
  ],
  "showSymbolLogo": true,
  "isTransparent": false,
  "displayMode": "adaptive",
  "colorTheme": "dark",
  "locale": "en"
        }`
    if (document.querySelectorAll(`script[id=tickercontainer]`).length < 1) {
      tickercontainer.current.appendChild(script)
    }
  }, [])
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js'
    script.type = 'text/javascript'
    script.setAttribute('id', 'technicalcontainer')
    script.async = true
    script.innerHTML = `
        { 
          "autosize": true,
          "symbol": "${option + ':' + asset}",
          "interval": "1",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "2",
          "locale": "en",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
                }`
    if (document.querySelectorAll(`iframe[id=technicalcontainer]`).length > 0) {
      document.querySelectorAll('[id=technicalcontainer]')?.forEach((i, k) => {
        if (i) {
          technicalcontainer?.current?.removeChild(i)
        }
      })
    }
    if (document.querySelectorAll(`script[id=technicalcontainer]`).length < 1) {
      technicalcontainer.current.appendChild(script)
    }
  }, [asset, option])

  useEffect(() => {
    getUser()
    getTraders()
    getTrades()
  }, [])
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 min-h-[450px] px-5">
        <div className="relative">
          {' '}
          <div className="grid lg:grid-cols-3 grid-cols-1 auto-cols-fr gap-3 lg:px-[50px] px-2">
            <div className="col-span-3">
              <div className="w-full px-2 h-[400px]">
                <div
                  className="tradingview-widget-container flex flex-col h-[400px] relative"
                  ref={technicalcontainer}
                >
                  <div className="tradingview-widget-container__widget"></div>
                  <div className="tradingview-widget-copyright"></div>
                  <img
                    alt="robot"
                    src="/asset/img/robot.png"
                    className="robot heart absolute"
                    title={user?.robot}
                    style={{
                      border:
                        user?.robot === 'INACTIVE'
                          ? '5px solid #00ffff'
                          : '5px solid #ff0000',
                    }}
                  />
                </div>
              </div>
              <div className="bg-slate-flex flex-col px-1 lg:w-[60%] w-full py-5 text-center py-1 border-2 border-slate-700  mx-auto rounded-sm justify-center">
                {activeTrades?.count > 0 && (
                  <p className="mx-auto">
                    {Math.floor(count / 60)}M:{Math.floor(count % 60)}S
                  </p>
                )}
                <ul
                  className="flex gap-5 justify-center"
                  id="myTab"
                  role="tablist"
                >
                  <li
                    className={option === 'BINANCE' ? 'active' : ''}
                    role="presentation"
                    onClick={() => {
                      setOption('BINANCE')
                      setAsset(assets.BINANCE[0])
                    }}
                  >
                    <a
                      className="nav-link topt text-light"
                      id="crypto-tab"
                      href="#crypto"
                    >
                      CRYPTO
                    </a>
                  </li>
                  <li
                    className={option === 'NASDAQ' ? 'active' : ''}
                    onClick={() => {
                      setOption('NASDAQ')
                      setAsset(assets.NASDAQ[0])
                    }}
                  >
                    <a
                      className="nav-link topt text-light"
                      id="stocks-tab"
                      href="#stocks"
                    >
                      STOCKS
                    </a>
                  </li>
                  <li
                    className={option === 'FX' ? 'active' : ''}
                    onClick={() => {
                      setOption('FX')
                      setAsset(assets.FX[0])
                    }}
                  >
                    <a
                      className="nav-link topt text-light"
                      id="forex-tab"
                      href="#forex"
                    >
                      FOREX
                    </a>
                  </li>
                </ul>
                <form className="my-5 trade" ref={tradeform}>
                  <span className="loadinging" style={{ display: 'none' }}>
                    <i
                      className="fa fa-spinner fa-spin text-light"
                      style={{ fontSize: '24px' }}
                    ></i>
                  </span>
                  <select
                    className="w-full bg-dark text-slate-600 px-5"
                    id="tradeopt"
                    name="symbols"
                    onChange={(e) => setAsset(e.target.value)}
                  >
                    {assets[option]?.map((o, k) => (
                      <option key={k} className="p-4">
                        {o}
                      </option>
                    ))}
                  </select>

                  <div
                    style={{
                      width: '100%',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}
                    className="h"
                  >
                    <input
                      required
                      type="number"
                      placeholder={user?.cur + '0.00'}
                      id="amount"
                      min="10"
                      name="amount"
                      className="tramtv bg-dark form-control p-2"
                      style={{
                        width: '98%',
                        borderRadius: '5px',
                        textAlign: 'center',
                        border: 'medium none',
                        fontFamily: 'digital',
                        fontSize: '16px',
                        color: '#ccc',
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}
                    className="h"
                  >
                    <select
                      className="bg-dark p-2 tradeint"
                      style={{
                        width: '98%',
                        border: 'transparent',
                        textAlign: 'center',
                        borderRadius: '5px',
                        fontFamily: 'digital',
                        fontSize: '16px',
                        color: '#ccc',
                      }}
                      type="number"
                      name="interval"
                      min="5"
                      placeholder="Secs"
                      step="1"
                      max="59"
                      id="sect"
                    >
                      <option value="5">5 Sec</option>
                      <option value="10">10 Sec</option>
                      <option value="30">30 Sec</option>
                      <option value="60">1MIN</option>
                      <option value="180">3MIN</option>
                    </select>
                  </div>

                  <div
                    className="holder btn btn-sm btn-danger"
                    style={{
                      width: '95%',
                      textAlign: 'center',
                      marginBottom: '5px',
                      display: 'none',
                    }}
                    id="exp"
                  ></div>
                </form>
                <span className="flex justify-between gap-5 table-responsive text-xl">
                  {' '}
                  <button
                    type="button"
                    onClick={() => trade('BUY')}
                    className="bg-green-400 py-1 px-2 w-[45%] flex gap-1 rounded-sm  justify-center"
                    data-type="buy"
                    id="btn_call"
                  >
                    <div>
                      <img
                        className="svg-ico-call h-[80%]"
                        src="/asset/img/chart/up.svg"
                      />
                    </div>

                    <span className="my-auto text-center">
                      <b>BUY</b>
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={() => trade('SELL')}
                    className="bg-red-400 py-1 px-2 w-[45%] flex gap-1 rounded-sm justify-center"
                    data-type="sell"
                    id="btn_put"
                  >
                    <div>
                      <img
                        className="svg-ico-put h-[80%]"
                        src="/asset/img/chart/down.svg"
                      />
                    </div>
                    <span className="my-auto text-center">
                      <b>SELL</b>
                    </span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 auto-cols-fr gap-3 lg:px-[50px] px-2">
          <div className="grid  grid-rows-2 gap-3 bg-slate-800">
            <div className="bg-slate-800 flex flex-col px-1 w-full py-5 text-center py-1 border-2 border-slate-400 rounded-sm justify-center">
              <p className="font-semibold text-3xl py-2">
                {user.cur}
                {Number(user.balance).toPrecision(6)}
              </p>
              <p className="font-light text-lg py-2">Balance</p>
              <Link
                to="/office/trading/deposit"
                className="flex gap-1 mx-auto bg-blue-400 px-3 py-2 mb-5"
              >
                <BiPlusCircle className="my-auto text-md " />
                <span className="text-md">ADD FUNDS</span>
              </Link>
              <p className="bg-blue-300 text-white p-3 text-center flex gap-3 justify-center">
                <span>{user.cstring}</span>
                <BiCopyAlt
                  className="text-xl cur"
                  title="Click to copy"
                  onClick={() => Copy(user.cstring)}
                />
              </p>
            </div>
            <div className="bg-slate-800 flex flex-col px-1 w-full py-5 text-center py-1 border-2 border-slate-400 rounded-sm">
              <p className="font-light text-lg py-2">MY TRADERS</p>
              <hr />
              {user?.traders &&
                user?.traders !== '' &&
                typeof user?.traders == 'string' &&
                traders
                  .filter((mt) =>
                    user?.traders?.split(',').toString()?.includes(mt.id),
                  )
                  ?.map((u, k) => (
                    <div key={k}>
                      <Link
                        to={'/office/traders/' + u.id}
                        className="bg-slate-300 p-2 w-full text-black flex gap-2 rounded-tr-lg rounded-bl-lg"
                      >
                        <img
                          src={
                            process.env.REACT_APP_SERVER.replace(
                              'app.php',
                              '',
                            ) + u.pic
                          }
                          className="rounded-full p-2 h-[50px] my-auto"
                          alt="trader"
                        />
                        <span className="text-center my-auto">{u?.name}</span>
                      </Link>
                    </div>
                  ))}
              {(!user?.traders || user?.traders === '') && (
                <div className="px-3 py-2 bg-slate-200 text-black">
                  No Copied Traders
                </div>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-1">
            <div className="py-1 border-2 border-slate-400 rounded-sm justify-center">
              <TradingViewWidget pair="GBPUSD" />
            </div>
            <div className="py-1 border-2 border-slate-400 rounded-sm justify-center">
              <div
                className="tradingview-widget-container"
                ref={tickercontainer}
              >
                <div className="tradingview-widget-container__widget"></div>
                <div className="tradingview-widget-copyright">
                  <a
                    href="https://www.tradingview.com/"
                    rel="noopener nofollow"
                    target="_blank"
                  >
                    <span className="blue-text"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className="py-1 border-2 border-slate-400 rounded-sm justify-center">
              <TradingViewWidget pair="USDCHF" />
            </div>
          </div>
        </div>

        <div className="bg-slate-700 py-10 px-2">
          <div className="flex justify-center gap-5 text-white text-2xl">
            {page > 1 && (
              <a
                onClick={() => setPage(page > 1 ? page - 1 : 1)}
                href="#tradehistory"
              >
                <FaArrowAltCircleLeft />
              </a>
            )}
            {page < Math.ceil(trades.length / 10) && (
              <a href="#tradehistory" onClick={() => setPage(page + 1)}>
                <FaArrowAltCircleRight />
              </a>
            )}
          </div>
          <div className="relative overflow-x-auto" id="tradehistory">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Assets
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Type
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Strike
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Result
                  </th>
                </tr>
              </thead>
              <tbody>
                {trades?.length > 0 &&
                  trades
                    ?.slice((page - 1) * 10, 10 + (page - 1) * 10)
                    .map((t, k) => (
                      <tr className="bg-white dark:bg-gray-800 " key={k}>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {user.cur + Number(t.amount).toLocaleString()}
                        </th>
                        <td className="px-6 py-4">{t.asset}</td>
                        <td className="px-6 py-4">{t.dt}</td>
                        <td className="px-6 py-4">{t.type}</td>
                        <td className="px-6 py-4">{t.strike}</td>
                        <td
                          className={
                            t.result == 'true'
                              ? 'px-6 py-4 text-green-400'
                              : 'px-6 py-4 text-red-400'
                          }
                        >
                          {t.status == 'OPEN'
                            ? 'OPEN'
                            : t.result == 'true'
                            ? 'Won'
                            : 'Lost'}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
